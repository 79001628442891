<template>
  <section id="product-abc">
    <b-card>
      <b-row>
        <b-col xl="6" md="6">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
        <b-col xl="6" md="6">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input v-model="searchValue" placeholder="Search" @keyup.enter="searchProduct" />
          </b-input-group>
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col xl="6" md="6">
          <v-select v-model="selectedPlatform" label="title" placeholder="Platform" :options="allPlatforms"
            @input="changeSelectedPlatform" />
        </b-col>
        <b-col xl="6" md="6">
          <v-select v-model="selectedDeliveryType" label="title" placeholder="Delivery Type" :options="allDeliveryTypes"
            @input="changeSelectedDeliveryType" />
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col xl="3" md="3">
          <label for="basic-password1">Class A</label>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="PercentIcon" />
            </b-input-group-prepend>
            <b-form-input v-model="percentageClassA" placeholder="Class A" type="number"
              @keyup.enter="submitPercentages" />
          </b-input-group>
        </b-col>
        <b-col xl="3" md="3">
          <label for="basic-password1">Class B</label>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="PercentIcon" />
            </b-input-group-prepend>
            <b-form-input v-model="percentageClassB" placeholder="Class B" type="number"
              @keyup.enter="submitPercentages" />
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="show" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Product ABC analysis
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-product-abc-analysis" />
          </h4>
          <b-popover target="popover-product-abc-analysis" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-overlay :show="showExportStart" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
              <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer" @click="exportTable" />
            </b-overlay>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="prodcutABCTable.currentPage"
          :items="prodcutABCTable.items" :fields="prodcutABCTable.fields" :sort-by.sync="prodcutABCTable.sortBy"
          :sort-desc.sync="prodcutABCTable.sortDesc" :sort-direction="prodcutABCTable.sortDirection"
          :filter="prodcutABCTable.filter" :filter-included-fields="prodcutABCTable.filterOn"
          @sort-changed="sortChanged" @row-clicked="productDetailsRowClicked" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="prodcutABCTable.currentPage" :total-rows="prodcutABCTable.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleProdcutABCTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
      <b-row>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Class Distribution
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-class-distribution" />
              </h4>
              <b-popover target="popover-class-distribution" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="classDistributionChart" :options="classDistributionChart"
              style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Class Distribution
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-class-distribution" />
              </h4>
              <b-popover target="popover-class-distribution" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <b-table striped hover responsive class="position-relative"
              :current-page="prodcutABCByClassTable.currentPage" :items="prodcutABCByClassTable.items"
              :fields="prodcutABCByClassTable.fields" :sort-by.sync="prodcutABCByClassTable.sortBy"
              :sort-desc.sync="prodcutABCByClassTable.sortDesc" :sort-direction="prodcutABCByClassTable.sortDirection"
              :filter="prodcutABCByClassTable.filter" :filter-included-fields="prodcutABCByClassTable.filterOn" />
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <b-modal ref="product-details-modal" size="xl" :title="productSKU" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true" @hidden="resetProductDetailsModal"
      @shown="onProductDetailsModalShown">
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Qty sold over years
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-qty-sold-years" />
              </h4>
              <b-popover target="popover-qty-sold-years" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="qtySoldByYearChart" :options="qtySoldByYearChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Avg. price over years
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-avg-price-years" />
              </h4>
              <b-popover target="popover-avg-price-years" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="avgPriceByYearChart" :options="avgPriceByYearChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCardBody,
  BCardHeader,
  BCardText,
  BPopover,
  BCard,
  BOverlay,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BModal,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import Ripple from 'vue-ripple-directive';
import axios from 'axios';
import * as XLSX from 'xlsx';
import ECharts from 'vue-echarts';
import 'echarts';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';

const currentDate = new Date();
const twelveMonthsAgo = new Date();
twelveMonthsAgo.setMonth(currentDate.getMonth() - 11);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BPopover,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BModal,
    vSelect,
    flatPickr,
    ToastificationContent,
    ECharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: true,
      showProductDetailsByYear: true,
      showExportStart: false,
      oldDateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      dateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      searchValue: '',
      selectedPlatform: '',
      selectedDeliveryType: '',
      allPlatforms: [],
      allDeliveryTypes: [],
      productSKU: '',
      percentageClassA: 80,
      percentageClassB: 15,
      queryParams: {},
      queryParamsByClass: {},
      classDistributionChart: {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return params.name + '<br/>' + `${params.marker} ${params.seriesName}: ${params.value.toLocaleString('de-DE')}<br/>`;
          }
        },
        series: [
          {
            name: 'Count',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ],
      },
      prodcutABCTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'turnover',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          { key: 'qty_sold', label: 'qty sold', sortable: true },
          {
            key: 'qty_sold_curr_year',
            label: 'qty sold current year',
            sortable: true,
          },
          {
            key: 'avg_pprice',
            label: 'avg. purchase price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'avg_sprice',
            label: 'avg. sales price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover',
            label: 'turnover',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover_curr_year',
            label: 'turnover current year',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'margin',
            label: 'margin',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'margin_p',
            label: 'margin %',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          { key: 'orders_count', label: 'orders count', sortable: true },
          {
            key: 'share_total_sales',
            label: 'share of total sales',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          { key: 'class', label: 'class', sortable: true },
          { key: 'stock_qty', label: 'stock qty', sortable: true },
          { key: 'min_stock', label: 'min stock level', sortable: true },
          {
            key: 'last_order_date',
            label: 'last order date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          { key: 'platform', label: 'platform', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      qtySoldByYearChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      prodcutABCByClassTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'class',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'class', label: 'class', sortable: true },
          {
            key: 'amount_articles',
            label: 'amount articles',
            sortable: true,
          },
          {
            key: 'total_turnover',
            label: 'total turnover',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'total_margin',
            label: 'total margin',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      avgPriceByYearChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
    }
  },
  async created() {
    const dateRange = this.dateRange.split(' to ');
    const percentageClassA = parseInt(this.percentageClassA) / 100;
    const percentageClassB = parseInt(this.percentageClassB) / 100;
    const percentageClassAB = (percentageClassA + percentageClassB).toFixed(2);

    this.queryParams.from_date = dateRange[0];
    this.queryParams.to_date = dateRange[1];
    this.queryParams.percentage_class_a = percentageClassA;
    this.queryParams.percentage_class_b = percentageClassAB;

    this.queryParamsByClass.from_date = dateRange[0];
    this.queryParamsByClass.to_date = dateRange[1];
    this.queryParamsByClass.percentage_class_a = percentageClassA;
    this.queryParamsByClass.percentage_class_b = percentageClassAB;

    try {
      await this.getPlatforms();
      await this.getDeliveryTypes();
      await this.getProdcutABC();
      await this.getProdcutABCByClass();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: 'BellIcon',
          variant,
        },
      })
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getPlatforms() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/platforms/`, {});
        this.allPlatforms = response.data.results.map(item => item.name);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getDeliveryTypes() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-delivery-types/`, {});
        this.allDeliveryTypes = response.data.results.map(item => item.name);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getProdcutABC() {
      this.show = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/products-abc/`, this.queryParams);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.prodcutABCTable.items = results;
          this.prodcutABCTable.totalRows = results[0].count * 2;
        }
        else {
          this.prodcutABCTable.items = [];
          this.prodcutABCTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.show = false;
      }
    },
    async getProdcutABCByClass() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/products-abc-by-class/`, this.queryParamsByClass);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.prodcutABCByClassTable.items = results;
          this.prodcutABCByClassTable.totalRows = 3;

          this.classDistributionChart.series[0].data = results.map(item => {
            return {
              value: item.amount_articles,
              name: item.class,
            };
          });
        }
        else {
          this.prodcutABCByClassTable.items = [];
          this.prodcutABCByClassTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getProductDetails() {
      this.showProductDetailsByYear = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/product-details-by-year/`, { sku: this.productSKU });
        const results = response.data.results;
        this.qtySoldByYearChart.xAxis.data = results.map(item => item.year);
        this.qtySoldByYearChart.series = {
          name: 'Items sold',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.qty_sold),
        };
        this.avgPriceByYearChart.xAxis.data = results.map(item => item.year);
        this.avgPriceByYearChart.series = {
          name: 'Avg. price',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.avg_price),
        };
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showProductDetailsByYear = false;
      }
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        this.queryParams.from_date = dateRange[0];
        this.queryParams.to_date = dateRange[1];
        this.queryParamsByClass.from_date = dateRange[0];
        this.queryParamsByClass.to_date = dateRange[1];

        await this.getProdcutABC();
        await this.getProdcutABCByClass();

        this.oldDateRange = this.dateRange;

        this.$nextTick(() => {
          this.$refs.classDistributionChart.refresh();
        });
      }
    },
    async searchProduct() {
      this.queryParams.page = 1;
      this.queryParams.search = '%' + this.searchValue.toLowerCase() + '%';
      await this.getProdcutABC();
    },
    async changeSelectedPlatform() {
      this.queryParams.platform = this.selectedPlatform;
      this.queryParamsByClass.platform = this.selectedPlatform;

      await this.getProdcutABC();
      await this.getProdcutABCByClass();

      this.$nextTick(() => {
        this.$refs.classDistributionChart.refresh();
      });
    },
    async changeSelectedDeliveryType() {
      this.queryParams.delivery_type = this.selectedDeliveryType;
      this.queryParamsByClass.delivery_type = this.selectedDeliveryType;

      await this.getProdcutABC();
      await this.getProdcutABCByClass();

      this.$nextTick(() => {
        this.$refs.classDistributionChart.refresh();
      });
    },
    async submitPercentages() {
      const percentageClassA = parseInt(this.percentageClassA) / 100;
      const percentageClassB = parseInt(this.percentageClassB) / 100;
      const percentageClassAB = (percentageClassA + percentageClassB).toFixed(2);

      if (percentageClassAB > 1) {
        this.showToast("warning", "The sum of the entered data is more than 100%!");
      }
      else {
        this.queryParams.percentage_class_a = percentageClassA;
        this.queryParams.percentage_class_b = percentageClassAB;
        this.queryParamsByClass.percentage_class_a = percentageClassA;
        this.queryParamsByClass.percentage_class_b = percentageClassAB;
        await this.getProdcutABC();
        await this.getProdcutABCByClass();
      }

      this.$nextTick(() => {
        this.$refs.classDistributionChart.refresh();
      });
    },
    async sortChanged(value) {
      if (value.sortDesc === true) {
        this.queryParams.ordering = `-${value.sortBy}`;
      } else {
        this.queryParams.ordering = value.sortBy;
      }
      await this.getProdcutABC();
    },
    async handleProdcutABCTablePageChange(value) {
      this.queryParams.page = value;
      await this.getProdcutABC();
    },
    async exportTable() {
      try {
        this.showExportStart = true;
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-products-abc/`, this.queryParams);
        const exportedData = response.data.results;
        this.showExportStart = false;
        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(exportedData);
        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // Export the workbook to an xlsx file
        XLSX.writeFile(workbook, 'ProductsABC.xlsx');
        link.click();
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    productDetailsRowClicked(row) {
      this.productSKU = row.sku;
      this.getProductDetails();
      this.$refs['product-details-modal'].show();
    },
    resetProductDetailsModal() {
      this.productSKU = '';
    },
    onProductDetailsModalShown() {
      this.$nextTick(() => {
        this.$refs.qtySoldByYearChart.resize();
        this.$refs.avgPriceByYearChart.resize();
      });
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
